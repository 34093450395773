import { useStaticQuery, graphql } from 'gatsby';

// get home page assets(icons) the gatsby way

const useStaticAssets = () => {
  const data = useStaticQuery(graphql`
    query {
      logoGrey: file(relativePath: { eq: "logo-grey.svg"}) {
        publicURL
      }
      logoWhite: file(relativePath: { eq: "logo-white.svg"}) {
        publicURL
      }
      lineArrow: file(relativePath: { eq: "icons/line-arrow.svg" }) {
        publicURL
      }
      menuButton: file(relativePath: { eq: "icons/menu-btn.svg" }) {
        publicURL
      }
      logoNoText: file(relativePath: { eq: "icons/logo-no-text.svg" }) {
        publicURL
      }
      menuCloseButton: file(relativePath: { eq: "icons/close-btn.svg" }) {
        publicURL
      }
    }
  `);

  return data;
};

export default useStaticAssets;
