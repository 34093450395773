// extracted by mini-css-extract-plugin
export var header = "Header-module--header--1Qt4T";
export var logo = "Header-module--logo--RPmCm";
export var variantDark = "Header-module--variantDark--1gjT-";
export var navLinkCurrent = "Header-module--navLinkCurrent--2k4bo";
export var desktopNav = "Header-module--desktopNav--V0W4l";
export var nav = "Header-module--nav--y_ARd";
export var mobileNavBtn = "Header-module--mobileNavBtn--2XLdc";
export var openBtn = "Header-module--openBtn--1Vemp";
export var closeBtn = "Header-module--closeBtn--xhqfP";
export var mobileNav = "Header-module--mobileNav--Mg9A4";
export var socials = "Header-module--socials--31S7u";