import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames/bind";
import gsap from "gsap";
import { useStaticAssets } from "@hooks/queries";
import * as style from "./Header.module.scss";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const cx = classNames.bind(style);
const navLinks = [
  {
    text: "about us",
    href: "/about",
    current: false,
  },
  {
    text: "projects",
    href: "#",
    current: false,
  },
  // {
  //   text: "blog",
  //   href: "#",
  //   current: false,
  // },
  {
    text: "contact",
    href: "/contact",
    current: false,
  },
  // {
  //   text: "careers",
  //   href: "#",
  //   current: false,
  // },
];

const Header = ({ variant, currentPage }) => {
  const { logoWhite, logoGrey } = useStaticAssets();

  const isOpen = useRef(false);

  const DOM = useRef({});
  const NAVBLOCK = ".header-nav"; // trying out some BEM style for selectors
  const LOGOBLOCK = ".header-logo";

  useEffect(() => {
    // hack
    window.Splitting();

    // select all dom nodes to be animated
    let els = {
      logo: document.querySelector(LOGOBLOCK + " img"),
      el: document.querySelector(NAVBLOCK),
      btn: document.querySelector(NAVBLOCK + "__btn"),
      openBtn: document.querySelector(NAVBLOCK + "__open"),
      closeBtn: document.querySelector(NAVBLOCK + "__close"),
      links: document.querySelectorAll(NAVBLOCK + " ul li a"),
      linkChars: document.querySelectorAll(NAVBLOCK + " ul li a .char"),
    };
    DOM.current = els;

    animateIn().play();
  }, []);

  const animateIn = () => {
    return gsap
      .timeline({
        paused: true,
        delay: 1.5,
        onComplete: () => {
          isOpen.current = true;
        },
      })
      .addLabel("start")
      .set([DOM.current.el.querySelectorAll(".overflow-hidden-anim")], {
        opacity: 1,
        pointerEvents: "all",
      })
      .to(DOM.current.logo, {
        duration: 0.5,
        y: "0",
      })
      .to(DOM.current.linkChars, {
        duration: 0.5,
        stagger: 0.014,
        y: "-100%",
      })
      .to(
        DOM.current.openBtn,
        {
          duration: 0.5,
          opacity: 0,
        },
        "start"
      )
      .to(
        DOM.current.closeBtn,
        {
          duration: 0.5,
          opacity: 1,
        },
        "start"
      );
  };

  return (
    <div
      className={cx(
        {
          header: true,
        },
        "header-nav"
      )}
    >
      <a
        href="/"
        className={cx("header-logo", "overflow-hidden-anim", { logo: true })}
      >
        <img
          src={logoWhite.publicURL}
          alt="logo"
          className="header-logo__image"
        />
      </a>
      <nav className={style.desktopNav}>
        <ul className={style.nav}>
          {navLinks.map((link, i) => (
            <li key={i}>
              <Link
                className={cx(
                  "overflow-hidden-anim",
                  "link-underline-hover",
                  {
                    navLinkCurrent: link.text === currentPage,
                  },
                  {
                    variantDark: variant === "dark",
                  }
                )}
                to={link.href}
                data-splitting="chars"
              >
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <MobileNav />
    </div>
  );
};

const MobileNav = () => {
  let { menuButton, menuCloseButton } = useStaticAssets();
  const isOpen = useRef(false);

  const DOM = useRef({});
  const NAVBTNBLOCK = ".mobile-nav-btn";
  const MOBILENAVBLOCK = ".mobile-nav";

  const openMobileMenu = () => {
    animateMenuIn().play();
  };

  const closeMobileMenu = () => {
    animateMenuOut().play();
  };

  const animateMenuIn = () =>
    gsap
      .timeline({
        paused: true,
      })
      .addLabel("start")
      .to(DOM.current.openBtn, {
        opacity: 0,
        display: "none",
        duration: 0.5,
      })
      .to(
        DOM.current.menuWrapper,
        {
          width: "100vw",
          duration: 0.6,
        },
        "start"
      )
      .to(DOM.current.links, {
        duration: 0.5,
        stagger: 0.2,
        x: 0,
        opacity: 1,
        ease: "power1.inOut",
      })
      .to(
        DOM.current.closeBtn,
        {
          opacity: 1,
          duration: 0.3,
          display: "initial",
        },
        ">-0.5"
      );

  const animateMenuOut = () => {
    return gsap
      .timeline({
        paused: true,
      })
      .addLabel("start")
      .to(DOM.current.closeBtn, {
        opacity: 0,
        display: "none",
        duration: 0.3,
      })
      .to(
        DOM.current.links,
        {
          duration: 0.5,
          stagger: 0.2,
          x: -30,
          opacity: 0,
          ease: "power1.inOut",
        },
        ">-0.3"
      )
      .to(DOM.current.menuWrapper, {
        duration: 0.5,
        width: "0",
      })
      .to(DOM.current.openBtn, {
        opacity: 1,
        display: "initial",
        duration: 0.5,
      });
  };

  useEffect(() => {
    const els = {
      menuWrapper: document.querySelector(MOBILENAVBLOCK),
      openBtn: document.querySelector(NAVBTNBLOCK + "__open"),
      closeBtn: document.querySelector(NAVBTNBLOCK + "__close"),
      links: document.querySelectorAll(MOBILENAVBLOCK + " li"),
    };
    console.log("menu-->", els.menuWrapper);
    DOM.current = els;

    DOM.current.openBtn.addEventListener("click", openMobileMenu);
    DOM.current.closeBtn.addEventListener("click", closeMobileMenu);
  }, []);

  return (
    <>
      <div className={cx("mobile-nav-btn", style.mobileNavBtn)}>
        <span className={cx(style.openBtn, "mobile-nav-btn__open")}>
          <img src={cx(menuButton.publicURL)} />
        </span>
        <span className={cx(style.closeBtn, "mobile-nav-btn__close")}>
          <img src={menuCloseButton.publicURL} />
        </span>
      </div>
      <div className={cx("mobile-nav", style.mobileNav)}>
        <ul>
          {navLinks.map((link, i) => (
            <li key={i}>
              <Link to={link.href}>{link.text}</Link>
            </li>
          ))}
        </ul>
        <div className={cx(style.socials, "mobile-nav-socials")}>
          <ul>
            <li>
              <a href="#">Facebook.</a>
            </li>
            <li>
              <a href="#">Instagram.</a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

Header.propTypes = {
  variant: PropTypes.string,
  currentPage: PropTypes.string,
};

export default Header;
